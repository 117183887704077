
import $ from 'jquery';
import classie from './classie';

$(function () {
    // eslint-disable-next-line no-undef
    var slider = new MasterSlider();
    slider.setup('masterslider', {
        width: 1200,
        height: 768,
        fullwidth: true,
        autoHeight: true,
        mouse: true,
        view: "basic"
    });
});

$(function () {
    var docElem = document.documentElement,
        header = document.querySelector('.fix-nav'),
        didScroll = false,
        changeHeaderOn = 100; // 離TOP 的高度 啟動
    function init() {
        window.addEventListener('scroll', function (event) {
            if (!didScroll) {
                didScroll = true;
                setTimeout(scrollPage, 250);
            }
        }, false);
    }
    function scrollPage() {
        var sy = scrollY();
        if (sy >= changeHeaderOn) {
            classie.add(header, 'fix-nav-shrink');
            $("#mobile-mu").addClass("active");
            $(".scrollTop").addClass("active");
        }
        else {
            classie.remove(header, 'fix-nav-shrink');
            $("#mobile-mu").removeClass("active");
            $(".scrollTop").removeClass("active");
        }
        didScroll = false;
    }
    function scrollY() {
        return window.pageYOffset || docElem.scrollTop;
    }
    init();

});

$(function () {
    $("a.target-burger").on('click', function (e) {
        $("#outerWp, nav.main-nav, a.target-burger, #mobile-mu").toggleClass('toggled');
        e.preventDefault();
    });
    $("ul.mu li a").on('click', function (e) {
        $("#outerWp, nav.main-nav, a.target-burger, #mobile-mu").removeClass('toggled');
        e.preventDefault();
    });
});



$(function () {
    $('header ul.menu li').on('click', function (e) {
        $(this).addClass('active').siblings().removeClass('active');
        e.preventDefault();
    });
});