import React from 'react';
import imageMap from './utils/imageMap';

function ClassOne() {
    return (
        <>
            <table className="mTable">
                <thead>
                    <tr>
                        <th colSpan="2">Time</th>
                        <td><i>16:30~18:00</i></td>
                        <td><i>18:30~20:00</i></td>
                        <td><i>20:15~21:45</i></td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th rowSpan="2">一</th>
                        <th>A</th>
                        <td></td>
                        <td><h2 className="adv">House</h2>
                            <h4>小穎</h4></td>
                        <td>
                            <h2 className="adv">Hip-Hop</h2><h4>Kaku</h4>
                        </td>
                    </tr>
                    <tr>
                        <th>B</th>
                        <td></td>
                        <td><h2 className="normal">Waacking</h2><h4>金瑋瑋</h4></td>
                        <td><h2 className="normal">Dancehall<br />Female</h2><h4>涵涵</h4></td>

                    </tr>
                    <tr>
                        <th rowSpan="2">二</th>
                        <th>A</th>
                        <td></td>
                        <td><h2 className="normal">Hip-Hop</h2>
                            <h4>Mark</h4></td>
                        <td><h2 className="adv">Hip-Hop</h2>
                            <h4>查理</h4></td>
                    </tr>
                    <tr>
                        <th>B</th>
                        <td></td>
                        <td><h2 className="normal">High Heels</h2><h4>宋靜</h4></td>
                        <td><h2 className="adv">Dancehall</h2><h4>小姬</h4></td>
                    </tr>
                    <tr>
                        <th rowSpan="2">三</th>
                        <th>A</th>
                        <td></td>
                        <td><h2 className="normal">Afro<br />FreeStyle</h2><h4>小姬</h4></td>
                        <td><h2 className="normal">Popping</h2><h4>龔妹</h4></td>
                    </tr>
                    <tr>
                        <th>B</th>
                        <td>
                            <div className="time">18:30 ~ 19:30</div>
                            <h2 className="started">基礎律動</h2>
                            <h4>胡婷</h4>
                        </td>
                        <td>
                            <div className="time">19:40 ~ 20:40</div>
                            <h2 className="started">Street Jazz</h2>
                            <h4>小俐</h4>
                        </td>
                        <td>
                            <div className="time">20:50 ~ 21:50</div>
                            <h2 className="started">Hip-Hop</h2>
                            <h4>昱豪</h4>
                        </td>
                    </tr>
                    <tr>
                        <th rowSpan="2">四</th>
                        <th>A</th>
                        <td></td>
                        <td><h2 className="adv">FreeStyle</h2>
                            <h4>卡拉</h4></td>
                        <td>
                            <h2 className="normal"><b>特別課程</b></h2>
                            <h4>每月<br />精選師資</h4>
                        </td>
                    </tr>
                    <tr>
                        <th>B</th>
                        <td></td>
                        <td>
                            <h2 className="normal">Girl Style</h2>
                            <h4>冉宥</h4>
                        </td>
                        <td><h2 className="normal">Choreography</h2><h4>七七</h4></td>
                    </tr>
                    <tr>
                        <th rowSpan="2">五</th>
                        <th>A</th>
                        <td></td>
                        <td>
                            <h2 className="normal">Hip-Hop</h2>
                            <h4>育玄</h4>
                        </td>
                        <td><h2 className="normal">FreeStyle</h2>
                            <h4>亨利</h4></td>
                    </tr>
                    <tr>
                        <th>B</th>
                        <td></td>
                        <td><h2 className="started">House</h2>
                            <h4>牛牛</h4>
                        </td>
                        <td><h2 className="started">Twerk</h2>
                            <h4>碗公</h4>
                        </td>
                    </tr>
                    <tr>
                        <th rowSpan="2">六</th>
                        <th>A</th>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>B</th>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th rowSpan="2">日</th>
                        <th>A</th>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>B</th>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
            <img src={imageMap['c-title.png']} className="mcTitle" alt="課程標題" />
            <table className="mTable">
                <thead>
                    <tr className="tr-white">

                        <th></th>
                        <th colSpan="2">六</th>
                        <th colSpan="2">日</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>

                        <th>11:00</th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>

                        <th>12:00</th>
                        <td><h4>&nbsp;</h4></td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td><h4>&nbsp;</h4></td>
                    </tr>
                    <tr>

                        <th>13:30</th>
                        <td>
                            <h2 className="started">兒童 MV</h2>
                            <h4>青軒</h4>
                        </td>
                        <td><h2 className="started">兒童 Hip-Hop</h2><h4>咖啡</h4></td>
                        <td>
                            <h2 className="started">兒童 MV</h2>
                            <h4>青軒</h4>
                        </td>
                        <td><h2 className="started">兒童 Popping</h2><h4>龔妹</h4></td>
                    </tr>
                    <tr>

                        <th>14:45</th>
                        <td></td>
                        <td></td>
                        <td>&nbsp;</td>
                        <td><h2 className="started">兒童 Hip-Hop</h2><h4>姿儀</h4></td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}

export default ClassOne;