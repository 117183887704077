import React from 'react';
import Navbar from './Navbar';
import Class from './Class';
import Location from './Location';
import './assets/scss/style.scss';
import $ from 'jquery';
import imageMap from './utils/imageMap';
window.jQuery = window.$ = $;

const Layout = () => {
    require('./assets/js/animatescroll');
    require('./assets/js/masterslider');
    require('./assets/js/other');


    const handleClick = () => {
        $('#class').animatescroll();
    }
    const otherClick = () => {
        $('#location').animatescroll();
    }
    const scrollTop = () => {
        $('html').animatescroll();
    }

    return (
        <>
            <Navbar />
            <div id="outerWp">
                <header className="fix-nav">
                    <div className="inner">
                        <a href="/" className="logo">
                            <img src={imageMap['logo.png']} alt="" />
                        </a>
                        <ul className="menu">
                            <li><a href="#" onClick={handleClick}><h1>課程介紹</h1></a></li>
                            <li><a href="#" onClick={otherClick}><h1>聯絡我們</h1></a></li>
                        </ul>
                    </div>
                </header>
                <div id="banner">
                    <div className="inner">
                        <div className="master-slider ms-skin-default" id="masterslider">
                            <div className="ms-slide" data-delay="1">
                                <img className="ms-layer"
                                    data-offset-x="0"
                                    data-offset-y="0"
                                    data-origin="tc"
                                    data-type="image"
                                    data-resize="true"
                                    data-effect="back(1000,true)"
                                    data-duration="1300"
                                    data-fixed="true"
                                    data-delay="0"
                                    data-parallax="4"
                                    data-ease="easeOutQuart"
                                    data-src={imageMap['banner.jpg']}
                                    alt="banner圖"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div id="content-wp">
                    <dl id="ind">
                        <dd id="about">
                            <div className="left"></div>
                            <div className="inner">
                                <div className="right">
                                    <h2>
                                        <img src={imageMap['logo.png']} alt="" />
                                        <b>ABOUT</b>
                                    </h2>
                                    <p>成立於2004年5月全名是Merry Monarc(h) 意指"快樂國度"，
                                        因為我們希望喜歡跳舞的人都不要忘了跳舞的最初衷 那就是 快樂!!，
                                        一個有別於一般舞蹈教室之教學方式 我們希望提供一種以舞蹈為，
                                        出發點的生活藝術與生活態度 在MM 不只讓您快樂的學會舞步，
                                        也讓您更自然的熱愛音樂去享受正確的舞蹈觀念 ENJOY 的態度以及，
                                        舞蹈文化藝術的定義 才是MM對於所有學員的期望 ，
                                        專業的教學、舒適的空間、貼心的課程設計，
                                        堅持最完整每堂課一個半小時教學 以及全台最優良師資，
                                        讓老師以 "能在MM敎課為榮" 也讓你真正喜歡上跳舞，
                                        讓你愛上這個舞蹈大家庭 更是MM不斷成長茁壯的原動力，
                                        希望大家一起加入我們這個快樂的大家族 MM
                                    </p>
                                </div>
                            </div>
                        </dd>
                        <dd id="class">
                            <Class />
                        </dd>
                        <dd id="location">
                            <Location />
                        </dd>
                    </dl>
                </div>
            </div>
            <a href="#" className="scrollTop" onClick={scrollTop}>
                <img src={imageMap['top.png']} alt="backToTop" />
            </a>
        </>
    );
};

export default Layout;  