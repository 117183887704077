import React from "react";
import $ from 'jquery';
window.jQuery = window.$ = $;

const Navbar = () => {
    const handleClick = () => {
        $('#class').animatescroll();
    }
    const otherClick = () => {
        $('#location').animatescroll();
    }
    return (
        <div id="mobile-mu">
            <a href="/" className="target-burger">
                <ul className="buns">
                    <li className="bun"></li>
                    <li className="bun"></li>
                    <li className="bun"></li>
                </ul>
            </a>
            <nav className="main-nav" role="navigation">
                <ul className="mu">
                    <li><a href="#" onClick={handleClick}><h1>課程介紹</h1></a></li>
                    <li><a href="#" onClick={otherClick}><h1>聯絡我們</h1></a></li>
                </ul>
            </nav>
        </div>
    );
}

export default Navbar;