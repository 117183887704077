import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import Layout from './Layout';
import Home from './Home';
import Class from './Class';
import Location from './Location';
// import FormPreview from './FormPreview';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="class" element={<Class />} />
                <Route path="location" element={<Location />} />
            </Route>
            {/* <Route path="form" element={<FormPreview />} /> */}
        </Routes>
    </BrowserRouter>
);


// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import FormPreview from './FormPreview';
// import './index.css'; // 請確保CSS文件路徑正確

// const root = ReactDOM.createRoot(document.getElementById('root'));

// root.render(
//     <FormPreview />
// );